import Vue from 'vue'
import App from './App.vue'
import router from './router'

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import './element-variables.scss'
import 'animate.css'
import '@/assets/css/iconfont/iconfont.css'
import '@/assets/css/main.css'

// 请求
import http from './network/http'

import GetImage from './network/getImg'

import Loading from '@/components/loading/hock'

import store from '@/store/index'
// import './element-variables.scss'
Vue.use(ElementUI)

Vue.config.productionTip = false
Vue.prototype.$http = http
Vue.prototype.GetImage = GetImage
Vue.use(Loading)

Vue.directive('title', {
  inserted (el, binding) {
    if (binding.value) {
      document.title = binding.value
    }
  }
})

Vue.directive('preventClick', {
  inserted (el, binding) {
    el.addEventListener('click', () => {
      if (!el.disabled) {
        el.disabled = true
        setTimeout(() => {
          el.disabled = false
        }, 1000)
      }
    })
  }
})



new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
