import Vue from 'vue'
import Vuex from 'vuex'
import actions from './actions'
import getters from './getters'
import mutations from './mutations'
import { getMonth } from '@/util/test'

const nearlyDate = getMonth(6)

Vue.use(Vuex)

export default new Vuex.Store({
  state () {
    return {
      tokenid: window.localStorage.getItem('TOKENID') || null,
      username: window.localStorage.getItem('USERNAME') || '',
      password: window.sessionStorage.getItem('PASSWORD') || '',
      userInfo: {},
      certificate: window.sessionStorage.getItem('CERTIFICATE') || 0,
      company: window.localStorage.getItem('COMPANY') || null,
      cartNum: window.sessionStorage.getItem('CART_NUM') || 0, // 购物车数量
      errImg: require('@/assets/img/zanwu1.jpg'),
      nearlyDate: nearlyDate,
      userId: window.sessionStorage.getItem('USERID') || null,
      sortType: window.localStorage.getItem('SORTTYPE') || 'card',
      scrolTop: 0,
      fullDate: window.sessionStorage.getItem('FULL_DATE') || null,
      lastDate: window.localStorage.getItem('LAST_DATE') || null,
      roles: window.localStorage.getItem('ROLES') || null, // 权限
      roleCompany: window.sessionStorage.getItem('ROLECOMPANY') || null,
      searchName: window.sessionStorage.getItem('SEARCH_NAME') || "",
      searchName1: window.localStorage.getItem('SEARCH_NAME1') || "",
      query: window.sessionStorage.getItem('QUERY_OBJ') ? JSON.parse(window.sessionStorage.getItem('QUERY_OBJ')) : null,
    }
  },
  actions,
  getters,
  mutations
})
