<template>
  <div id="app">
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"></router-view>

  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import { getTime } from '@/util/test';
import bus from '@/bus.js';
export default {
  data() {
    return {
      isShow: false
    }
  },
  computed: {
    ...mapState(['scrolTop', 'roles', 'lastDate', 'tokenid'])
  },
  methods: {
    ...mapActions(['SET_SCROLLTOP', 'flushToken', 'Logout', "SET_TOKEN"]),

    scrollMethod(e) {
      if (this.$route.meta.keepAlive) {
        this.SET_SCROLLTOP(e.target.scrollTop)
      }
    }
  },
  watch: {
    $route(to, from) {
      //  /createOrder
      // if(to.path == "/createOrder"){
      //   if(to.query.tokenid){
      //     this.SET_TOKEN(to.query.tokenid);
      //     //bus.$emit('tokenid',to.query.tokenid)
      //   }
      // }
      // console.log(to,from)
      // console.log(to.path);
      if (window.localStorage.getItem('TOKENID')) {
        const date = getTime()
        if (!this.roles) {
          this.flushToken()
        } else if (!this.lastDate) {
          this.flushToken()
        } else if (date > this.lastDate && to.path == "/") {
          this.Logout();
          // alert('已掉线，请重新登录');
          // this.$router.replace('/login')
        }
      }
    }
  },
  mounted() {
    window.addEventListener('scroll', this.scrollMethod, true)
  },
  destroyed() {
    window.removeEventListener('scroll', this.scrollMethod)
  }
}
</script>
<style scoped lang="scss"></style>
