export default {
  // 修改tokenid
  SET_TOKEN1 (state, token) {
    state.tokenid = token
  },
  SET_USERNAME1 (state, username) {
    state.username = username
  },
  SET_PASSWORD1 (state, password) {
    state.password = password
  },
  SET_USERID1 (state, userid) {
    state.userId = userid
  },
  SET_CART_NUM (state, num) {
    state.cartNum = num
  },
  SET_SORT_TYPE1 (state, type) {
    state.sortType = type
  },

  SET_SCROLLTOP1 (state, num) {
    // window.sessionStorage.getItem
    state.scrolTop = num
  },
  SET_ROLES1 (state, role) {
    state.roles = role
  },
  SET_DATE1 (state, date) {
    state.lastDate = date
  },
  SET_COMPANY1 (state, company) {
    state.company = company
  },

  SET_CERTIFICATE1 (state, certificate) {
    state.certificate = certificate
  },

  SET_ROLE_COMPANY1 (state, company) {
    state.roleCompany = company
  },

  SET_SEARCH_NAME1(state, name) {
    state.searchName = name;
  },

  SET_SEARCH_NAME3(state, name) {
    state.searchName = name;
  },

  SET_QUERY1(state, obj) {
    state.query = obj;
  }
}
